#navbar {
  margin: 0 !important;
  .logo {
    padding: 0 !important;
  }

  a {
    font-size: 16px;
    color: black;
  }

  .disabled-link {
    pointer-events: none;
  }
}