.ui.main_header {
  text-align: center;

  margin-bottom: calc(var(--vertical-padding) * 2);
}

.ui.sub_header {
  text-align: center;
  color: #2185d0;
  margin-bottom: var(--vertical-padding);
}

.ui.divider {
  margin-top: calc(var(--vertical-padding) * 3);
}
