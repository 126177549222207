#nps-section {
  background-color: #f8f8f8;
  border-top: 1px solid #e7e7e7;
  text-align: center;
  padding: 10px;
  position: fixed;
  left: 0;
  bottom: 0;
  height: auto;
  margin-top: -150px;
  width: 100%;

  .nps-dashboard {
    width: 550px;
    max-width: 100%;
    box-sizing: border-box;
    border: 1px solid #ddd;
    background: #fbfbfb;
    border-radius: 5px;
    margin: auto;
    padding: 15px 0 15px;
    box-shadow: 5px 5px 5px 0 rgba(0,0,0,.05);
  }
  .nps-number {
    border: 1px solid #DDDDD;
    border-radius: 5px;
    padding: 5px 0;
    margin: 5px 3px;
    text-decoration: none;
    display: inline-block;
    width: 30px;
    background-color: #ffffff;
    color: #29292a;
    box-shadow: 3px 2px 10px 0 rgba(0, 0, 0, 0.15);
  }
  .scale {
    padding: 1rem 2rem 1rem 2rem;
  }
  .survicate {
    color: #AAAAAA;
    text-align: center;
    font-size: 12px;
    margin-top: 15px;
    text-decoration: none;
  }
}

@media screen and (max-width: 500px) {
  #nps-section {
    max-height: 150px;
    overflow: auto;
    .nps-number {
      width: 90% !important;
      margin: 5px auto !important;
    }
  }
}
