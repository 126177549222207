@use "../base/colors";

#meeting, #error {
    width: 50%;
    background: #fff;
    border: 1px solid transparent;
    margin-left: 1rem;
    margin-top: 3rem;
    padding: 1rem 3rem 3rem 3rem;}
    #meeting{
    .meeting-datetime {
        grid-area: datetime;
        width: 100%;
        display:flex;
        justify-content: center;
        align-items: center;
      }
      .meeting-invite {
        grid-area: invite
      }
      .action-btns {
        grid-area: primaryBtns;
        margin: auto;
        width: 80%;
        Button {
            width: 100%
        }}
    .project-description {
        grid-area: projectDescription;
        #description {
            margin-top: 1rem;
            max-height:150px;
            overflow-y: auto;
            line-height: 1.2rem;
        }
    
    }
    .pre-interview-material {
        grid-area: preInterviewMaterial;
        #pre-interview-link {
            margin-top:1rem;
            line-height: 1.2rem;
            a {
                text-decoration: underline;
                font-weight:bold
            }
        }
    }
      .secondary-action-btns{
        grid-area: secondaryBtns;
        width:100%;
        margin: auto 0;
        #report-no-show {
            cursor: pointer;
            line-height: 2rem
        }
        #report-no-show.disable-no-show {
            color: lightgrey;
            cursor: not-allowed
        }
        #view-upcoming-meetings {
            color: colors.$default;
        } 
      }
    
      }
    .grid-container {
        display: grid;
        width:100%;
        grid-template-columns:1fr 2fr;
        grid-auto-rows: minmax(90px, auto);
        grid-template-areas: 
            "datetime preInterviewMaterial"
            "primaryBtns invite"
            "projectDescription invite"
            "secondaryBtns invite";
        column-gap: 3rem;
        row-gap: 1%;
    }
    .grid-container.no-material {
        grid-template-areas:
        "datetime invite"
            "primaryBtns invite"
            "projectDescription invite"
            "secondaryBtns invite";
    }
    @media only screen and (max-width: 1199px ){
        #meeting {
            padding: 1rem;
            .action-btns {
                width: 80%
            }
            .meeting-invite {
               
                #meeting-invite-list {
                    margin: 1rem 0 0 auto;
                    width: 100%
                }  
            }
            .pre-interview-material, .meeting-invite{
                width:90%;
                margin: 0 0 0 auto;
            }
            .grid-container {
                column-gap: 1rem;
            }
        }
       
    }
    @media only screen and (max-width: 991px) {
        #meeting, #error {
            padding: 3rem;
        }
        #meeting {
            .action-btns{
                width: 60%;
            }
            .project-description {
                margin-top: 2rem;
                #description {
                    max-height:200px;
                }
            
            }
            .meeting-invite {
                #meeting-invite-list {
                    margin: 2rem auto 0 0;
                    width:100%
                }  
            }
            .meeting-invite, .pre-interview-material {
                width:100%
            }
            .grid-container {
                grid-template: 50px 100px 3fr 80px 1.75fr 150px / 80vw;
                row-gap:3%;
                grid-template-areas:
                    "datetime"
                    "primaryBtns"
                    "invite"
                    "preInterviewMaterial"
                    "projectDescription"
                    "secondaryBtns"
            }
            .grid-container.no-material {
                grid-template-areas:
                "datetime"
                "primaryBtns"
                "invite"
                "projectDescription"
                "secondaryBtns"
            }
            .secondary-action-btns{
                width: 100%;
                margin-top:0;
            }
           
        }
        }
        
