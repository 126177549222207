.dayz {
  height: 650px !important;
  .day {
    cursor: pointer;
    .event .evbody {
      background-color: lightgreen !important;
    }
  }
  .day.highlight {
    background-color: white !important;
    border: 3px gray solid !important;
  }
}

@media (max-width: 765px) {
  .dayz {
    height: auto !important;
    .x-labels .day-label {
      font-size: 12px;
    }
  }
}
