@use "../base/colors";

%box {
  text-align: center;
  .column {
    padding: 1.5rem;
  }
  .column:hover {
    background: colors.$hover;
    h5, p {
      color: #fff;
    }
  }
  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
  h5 {
    font-size: 28px;
  }
  p {
    color: black;
  }
}

#dashboard {
  padding: 5rem;
  .dashboard-content {
    .title {
      color: black;
      h1 {
        font-size: 70px;
      }
      .sub.header {
        margin-top: 2rem;
        font-weight: bold;
        letter-spacing: .2em;
      }  
      @media only screen and (max-width: 768px) {
        h1 {
          font-size: 50px;  
        }
      }
    }
    .stats {
      padding-top: 5rem;
      @extend %box;
      h5 {
        color: colors.$green;
      }
    }
  }
  .quick-links {
    margin-top: 10rem;
    padding: 2.5rem;
    .menu {
      @extend %box;
      h5 {
        color: black;
      }
      .column {
        margin: 1rem;
        background: colors.$grey-background;
      }
    }
    @media only screen and (max-width: 768px) {
      .menu {
        margin-top: 2.5rem !important;
        .column {
          margin-top: 1.5rem !important;
        }
      }
    }
  }
  .dashboard-footer {
    margin-top: 5rem;
    text-align: center;
    span, a {
      color: #fff !important;
      padding: 5rem;
    }
    @media only screen and (max-width: 768px) {
      span, a {
        padding: 1rem; 
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #dashboard {
    padding: 2.5rem;
  }
}
