:root {
  --accent-color: #ebfafe;
  --border-color: rgba(0, 0, 0, 0.2);
  --side-padding: 20px;
  --vertical-padding: 40px;
}

.question_item {
  margin-top: var(--vertical-padding);
  margin-bottom: var(--vertical-padding);
}

.highlight {
  background-color: var(--accent-color);
}

.highlight_text {
  border-bottom: 1px solid var(--border-color);
  font-weight: 600;
}
.answer_body {
  margin-left: var(--side-padding);
  margin-right: var(--side-padding);
}
