$color-green:#1B5E82;
$color-grey: #697A8A;

#public-project-landing{
    background-color: #F6F7F7;
    min-height: 100vh;
    width:100%;
    padding-top: 3rem;

    #contact {
        position: absolute;
        top: 100px;
        padding-right: 1.3rem;
    
        #contact-text {
                overflow-wrap: break-word;
                word-wrap: break-word;
                -ms-word-break: break-all;
                word-break: break-word;
            
        }
    }

    .content-block{
        background-color: white;
        padding: 1rem;
        margin: 1rem 0;
    }

    .general-section, .expert-section {
        margin: 0 !important;
        .content {
            margin: 0 !important
        }
    }

    .form {
        padding: 1rem;
    }

}
#public-project-register {
    background-color:white;
    padding: 2rem;
    margin-bottom: 1rem;

    #public-project-btn{
        padding-top: 3rem;
        display: flex;
        justify-content: center;
    }
}

#public-project-layout {
    display: grid;
    gap: 1rem;
    grid-template-columns:1fr;
    grid-template-areas:
    "header" 
    "faqs"
    "about"
    "qualifications"
    "next"
    
}
#public-project-layout{
    margin-bottom:10rem;
    #header{
        grid-area: header;
        
    }
    .project-description {
        max-height: 300px;
        overflow-y: scroll
    }
    #about-project, #faqs, #qualifications {
        background-color: white;
        padding: 1rem;
    }
    #about-project {
        grid-area: about;
        
    }
    #qualifications {
        grid-area:qualifications;

        #qualifications-header {
            padding-bottom: 1rem;
        }
        #qualifications-response-column {
            font-weight: 900;
        }
    }
    #faqs {
        grid-area: faqs;
    
    }
      #faqs-content {
        padding: 1rem;
    }
    #faqs-content .item {
        line-height: 1.4em !important;
    }
    #public-project-btn{
        grid-area: next;
        width:100%;
        display: flex; 
        justify-content: flex-start;
        align-items: flex-end;
        #btn-box {
            justify-content: center;
            width: 100%;
            display:flex;
        }
        
        #decline {
            padding-left: 1rem;
            text-decoration: none;
            color: $color-grey;
            font-weight: 900;
        }
    }
    #characters-remaining {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        color: #697A8A
    }
    #public-project-btn {
        display:flex;
        justify-content: space-between;
        width: 60%;
        #decline {
            background-color: #dd4b39;
            color: white
        }
    }
    }

#public-landing-schedule {
    width: 100%;
    #scheduler {
        border: 1px solid #EBEBEB;
        padding: 1rem
    }

 

}

#project-fields {
    padding: 1rem;
    .item {
        line-height: 1.4em !important;
    }
}

#qualification-statement {
    margin: 0.5rem 0;
    font-weight: bold;
}

#qualifications-response{
    padding: 1rem;
}

#qualification-elaboration {
    padding-left: 1.5rem;
}
.qualification-responses-landing {
    margin-top: 1.5rem
}
#public-project-btn{
    .primary-btn {
        padding-left: 4rem;
        padding-right: 4rem;
        margin-right: 1rem;
        background-color: $color-green;
    }
    #secondary-btn{
        background-color: $color-green;
        color:white !important;
        border: 1px solid $color-grey !important;

    }
}
.public-project-form-btn {
   
    display: flex;
   
    margin-bottom: 5rem;
    button#schedule {
        background-color: $color-green;
        padding: auto 3rem;
    }
    button#back{
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
.double-btn {
    width:55%; 
    justify-content: space-between;
}
.single-btn {
    width: 100%;
    justify-content: center
}

    
#add-calendar-btn {
    align-items: center;
  background-color: #fff;
  border: 1px solid $color-green;
  padding: calc(.5em - 1px)  2rem;
  border-radius: .375em;
  box-shadow: none;
  box-sizing: border-box;
  color: $color-green;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  justify-content: center;
  position: relative;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  white-space: nowrap;

}
#add-calendar-container {
    width:100%;
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    align-items: flex-end;
}

@media only screen and (max-width: 450px) {
    #public-project-landing {
        padding-top: 1rem;
    }
    #public-landing-schedule {
        max-width: 100%;
        #scheduler {
            border: none;
            max-width:90vw;
        }
    }
    #public-project-layout {
    #public-project-btn {
        #btn-box {
            display:flex;
            flex-direction: column;
            align-Items:center;
            justify-content: center;
            margin-left: 0%;
            width:100%;
            button {
                margin-bottom: 1rem;
                width:100%
            }
        }
    }}
}