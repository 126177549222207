.project-header {
  padding-bottom: 0;
}

#project-section {
  .navbar-nav {
    margin-left: 0;
    flex-direction: row;
    li {
      width: 10%;
      margin-right: 50px;
      text-align: center;
    }  
  }
  .pending-cards {
    width: 100% !important;
  }
  .payment-link {
    color: green !important;
  }
  .show-content-button {
    color: grey !important;
    display:flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 0.5em;

  }
  .pending-cards .ui.card,
  .ui.cards > .card {
    width: auto !important;
    height: auto !important;
    margin: 10px;
    padding: 10px;
  }
  .pending-cards .ui.two.buttons {
    width: 50%;
    margin-left: 30%;
  }
  .card-content {
    overflow: auto
  }
}

.ui.cards {
  display: block !important;
  clear: both;
}

.ui.message .header {
  margin-bottom: 5px !important;
}

.time-list,
.reason-list {
  list-style: none;
}

.time-list {
  margin-left: 100px;
}

.time-list li {
  margin-top: 10px;
}

.reason-list {
  margin-left: 20px;
}

.reason-list li {
  background: #fff;
  border: 1px solid grey;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  display: inline-block;
  margin: 10px;
  cursor: pointer;
}

.rating-section {
  margin-left: 25%;
  margin-bottom: 10px;
}

.rating-section i {
  margin-right: 10px;
  font-size: 36px;
  cursor: pointer;
}

.cards-controler {
  margin-bottom: 5px;
  height: 30px;
}

.cards-controler .status-count {
  display: inline;
}

.cards-controler .sort-by {
  float: right;
  color: black;
  font-weight: bold;
}

.load-more {
  cursor: pointer;
  margin-left: 40%;
  margin-top: 50px;
  margin-bottom: 100px;
  font-weight: bold;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.disabled i,
.disabled span {
  color: grey;
}

.active-link {
  color: darkgreen !important;
  text-decoration: underline !important;
  font-weight:bolder
}

.active-zoom {
  margin-top: 0.6rem;
}

@media only screen and (max-width: 1000px) {
  .modal-content {
    margin-top: 20% !important;
  }

  .project-cards .project-rate,
  .cards-controler .sort-by {
    float: none;
  }

  .cards-controler {
    margin-bottom: 10%;
  }

  #project-section .ui.two.buttons {
    width: 100% !important;
    margin: auto !important;
  }
}
