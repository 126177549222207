.step-progress {
  color: black !important;
}
.require-star {
  color: red;
}

.step-btn {
  background: rgb(21, 173, 113);
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: none !important;
  margin-bottom: 100px;
}

.fields .field {
  margin-top: 10px !important;
}

ol.progtrckr {
  list-style-type: none;
  padding: 0;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
}

ol.progtrckr li span {
  padding: 0 1.5rem;
}

.agreement {
  float: right;
  width: 40%;
  padding: 0.5rem 1rem 0 0;
}

@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}

ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #cccccc;
}

ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid #5cb85c;
}

ol.progtrckr li:after {
  content: '\00a0\00a0';
}

ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
  content: '\039F';
  color: silver;
  background-color: white;
  width: 1.2em;
  line-height: 1.4em;
}

ol.progtrckr li.progtrckr-todo:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-doing:before {
  content: '\2022';
  color: white;
  background-color: #cccccc;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-doing:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-done:before {
  content: '\2713';
  color: white;
  background-color: #5cb85c;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}

.greeting,
.sub-greeting {
  color: black;
  margin: 1rem 1rem 1rem 0 !important;
  margin-top: 50px !important;
}

.sub-greeting {
  margin-top: 0 !important;
}

@media (max-width: 768px) {
  .step-progress {
    width: 100% !important;
  }

  h1 {
    font-size: 28px;
  }
  .require-indication {
    margin-top: 100px;
  }
  .agreement {
    float: left;
    margin: 1rem;
    width: 100%;
  }
}
