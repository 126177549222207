.loading-text {
  text-align: center;
  color: #0066cc;
}

.loading-section {
  margin: 350px 100px 100px;
}

.loading-icon {
  margin-left: 50%;
  margin-bottom: 3%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*=================
  windows resize
==================*/
@media only screen and (max-width: 768px) {
  .loading-section {
    margin-top: 200px;
  }
}
