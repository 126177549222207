#payment {
  width: 70vw;
  margin-top: 50px;
  .bank-section {
    margin-top: 5%;
    h6 {
      margin-top: 50px;
    }
    .stripe-message {
      width: 50%;
      margin: auto;
      margin-top: 5%;
      margin-bottom: 50px;
      color: grey;
      background: rgb(246, 249, 252);
      position: static;
    }
    .empty-graph {
      width: 100%;
      height: 100%;
    }
    .connect-section .field {
      text-align: center;
      h3 {
        display: inline-block;
      }
      .ui.fluid.dropdown {
        width: 43% !important;
        padding-left: 1%;
        margin-left: 1%;
        display: inline-block;
      }      
    }
    .transcation-section {
      margin: 3%;
      margin-left: 1%;
      .transcation-info {
        .data-part {
          width: 30%;
          margin: auto;
          margin-top: 5%;
          margin-bottom: 5%;
          .ui.statistics {
            flex-wrap: nowrap !important;
          }
        }
        .button-part {
          text-align: center;
          a {
            margin-bottom: 10px;
          }
        }
      }
      .transcation-graph
        #transcation-graph-dropdown {
        float: right;
        z-index: 999;
      }
    }
    .ui.icon.message {
      width: 80%;
      margin: auto;
      margin-top: 5%;
      margin-bottom: 5%;
      .message-tip {
        font-weight: bold !important;
      }
      .profile-link {
        color: green !important;
      }
      .icon:not(.close) {
        font-size: 2em;
      }
    }
  }
  #stripe_illustration {
    width: 50%;
    height: 45%;
    margin-left: 25%;
    margin-bottom: 20px;
  }
  .bank-status {
    margin-top: 10px;
    text-align: center;
  }
  #stripe-message-icon {
    width: 100px;
    margin-left: 10px;
  }
  .contact-link {
    color: rgb(44, 100, 158) !important;
  }
}

#payment
  .bank-section
  .connect-section
  .transcation-info
  #disconnect-stripe-btn {
  text-decoration: underline !important;
  cursor: pointer;
  color: grey !important;
}

#payment .bank-section .connect-section .transcation-info .ui.primary.button,
.ui.primary.buttons .button {
  color: white !important;
}

#payment .bank-section .ui.dropdown > .text {
  padding-left: 20px;
}

#payment .bank-section .no-data-message {
  text-align: center;
  font-weight: bold;
  display: none;
}

.histogram {
  height: 40vh;
  padding-top: 10px;
  padding-left: 0px;
  padding-bottom: 5%;
  background-color: #fff;
}

.custom-tooltip {
  border: 1px solid black;
  background: white;
  padding: 10%;
  text-align: center;
  width: 300px;
  opacity: 0.8;
}

.custom-tooltip h5 {
  margin-bottom: 1px;
}

@media only screen and (max-width: 1000px) {
  #payment {
    width: 90%;
    margin-right: 10px;
  }

  #payment #stripe_illustration {
    height: 20%;
    margin: 0;
  }

  #payment .bank-section .stripe-message {
    width: 90%;
  }

  #payment .bank-section .stripe-message {
    font-size: 12px;
    margin-top: 10%;
  }

  #payment .bank-section .ui.icon.message,
  #payment .bank-section .no-data-message,
  #payment .bank-section .connect-section .field .ui.fluid.dropdown,
  #payment #stripe_illustration,
  #payment .bank-section .stripe-message {
    width: 100% !important;
  }

  #stripe-message-icon {
    width: 50px;
    margin-left: 10px;
  }

  #payment .bank-section .transcation-section .transcation-info .data-part {
    width: 70%;
  }

  #payment .bank-section .no-data-message {
    display: block;
  }

  #payment .bank-section .empty-graph {
    display: none;
  }
}
