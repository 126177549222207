@use "../base/colors";
@use "../layout/formModal";

#project-settings {
  float: right;
  .dropdown.icon {
    display: none !important;
  }
  a {
    color: colors.$default;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0 !important; 
  }
}

#project-settings:hover {
  background: rgb(244, 242, 237);
  color: colors.$primary !important;
  border-radius: 20px;
}

#ellipsis-menu {
  color: colors.$default;
  cursor: pointer;
}

.setting-modal-layout {
  @extend %form-modal;
}