#profile {
  width: 85vw;
  .navbar-nav {
    margin-left: 0;
    flex-direction: row;
    li {
      width: 25%;
      margin-right: 5%;
      text-align: center;
    }
  }
  .profile-title {
    position: relative;
    .user-profile {
      position: relative;
      .user-pic {
        position: relative;
        margin: 2.5rem 0 2.5rem 0;
        img {
          width: 200px;
          height: 200px;
          max-width: 100%;
          max-height: 100%;
          border-radius: 100px;
          filter: brightness(70%);
        }
        .upload-pic-btn {
          position: absolute;
          top: 80%;
          left: 55%;
          transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          cursor: pointer;
          width: 100%;
        }
        .upload-pic-btn i,
        .remove-pic-btn i,
        .upload-pic-btn span,
        .remove-pic-btn span {
          color: white;
          margin-right: 5px;
          font-size: 12px;
        }
      }
    }
  }
  .form-section {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1.5rem 5rem 1.5rem 5rem;
    overflow-y: auto;
    max-height: 70vh;
    .popover-content {
      background: #f6f8c0 !important;
    }
  }
  .require-indication {
    font-size: 14px !important;
  }
  .profile-btn-section {
    margin-top: 1.5rem !important;
  }
  @media only screen and (max-width: 1000px) {
    width: auto;
    .form-section {
      padding: 1rem;
      .questions small {
        margin-left: 20%;
      }
    }
    .require-indication {
      margin: 0;
      margin-left: 30%;
      margin-bottom: 10%;
      display: flex;
    }
  }
}

.user-info {
  margin: auto;
  width: 100%;
  height: 100%;
  overflow: auto;
  h2, h3, h4, h5 {
    color: black;
  }
}

.flag-select {
  padding-top: 0.5em !important;
  padding-bottom: 0 !important;
}

.flag-select__btn {
  font-size: 14px !important;
}

/*=================
  windows resize
==================*/
@media only screen and (max-width: 768px) {
  #profile {
    margin-top: 2rem;
    .profile-title .user-pic img {
      margin-left: 1%;
      width: 150px;
      height: 150px;
    }
  
    .profile-title small,
    .profile-title .fa-edit {
      display: none;
    }
  
    .user-pic .upload-pic-btn {
      left: 75%;
    }
  }
}
