@use "colors";

@font-face {
  font-family: SofiaProLight;
  src: url('../../fonts/SofiaProLight.otf') format('opentype');
}

@font-face {
  font-family: SofiaProMedium;
  src: url('../../fonts/SofiaProMedium.otf') format('opentype');
}

html,
body {
  font-family: SofiaProLight !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: SofiaProLight !important;
}

h1 {
  font-size: 36px;
}

h2 {
  color: hsl(90, 14%, 11%) !important;
}

h3 {
  color: hsla(90, 14%, 11%, 0.7) !important;
}

.btn {
  text-transform: none !important;
}

.btn-secondary {
  background: #c4c4c4 !important;
  color: black !important;
}

.blue-box {
  color: colors.$label-blue !important;
  background-color: colors.$label-light-blue !important;
  border: 1px solid colors.$label-blue !important;
}

.span-btn {
  cursor: pointer;
}

#main {
  margin: 0 auto;
  padding-bottom: 2.5rem;
}

.bold-text {
  font-weight: bold !important;
}

.ui.modal {
  height: auto !important;
}

@media (max-width: 765px) {
  #main {
    width: auto;
  }
  .save-btn {
    width: auto;
  }
}
