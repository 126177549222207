.welcome-page {
  text-align: center;

  h1, h3, h4 {
    color: black !important;
  }

  h3, h4 {
    margin: 0 !important;
  }

  .btn {
    background: rgb(21, 173, 113);
    padding: 1% 5% 1% 5%;
    color: white;
    font-weight: bold;
    border: none;
    font-size: 18px;
    border-radius: 5px;
    letter-spacing: 2px;
    margin-top: 5%;    
  }

  .btn:hover {
    background: rgb(52, 109, 205);
    color: white;    
  }

  #skip-for-now {
    text-decoration: underline;
    color: hsl(87, 30%, 54%);    
  }

  img {
    width: 100%;
    height: 60%;    
  }

  @media (max-width: 765px) {
    img {
      width: 350px;
      height: 250px;
    }
  }

}