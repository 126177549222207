.autocomplete-readOnly {
  .input {
    input {
      color: #999 !important;
      pointer-events: none !important;
    }
    i.icon {
      opacity: 1 !important;
    }
  }
}