@use "../base/colors";

pre {
    padding: 1rem;
    background: colors.$label-grey;
    color: colors.$default;
    max-height: 35vh;
    overflow-y: auto;
  }
  #zoom-info-btns {
    display: flex;
    justify-content: space-between;
  }
  @media only screen and (max-width: 767px){
    
    #zoom-info-btns {
      display: flex;
      flex-direction: column;
      width: 60%;
      margin: 0 auto;
      .primary-btn {
        margin-bottom: 1rem
      }
      #zoom-link {
        width: 55%;
  
      }
    }

  
  }