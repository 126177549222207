@use "../base/colors";

#schedule-confirmation {
  .item {
    margin-bottom: 1rem !important;
    .content {
      .description {
        color: colors.$default !important;
        margin-top: 1rem !important;
      }
    }
    .input {
      float: right !important;
    }
  }
  pre {
    padding: 1rem;
    background: colors.$label-grey;
    color: colors.$default;
    max-height: 35vh;
    overflow-y: auto;
  }
}